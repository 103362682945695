import { motion } from 'framer-motion';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 2.5em;
  ${p => p.theme.maxWidth.tablet`
    padding: 2em;
  `}
`;

const Container = styled(motion.div)`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 365px;
  max-height: 365px;
  border-radius: 100%;
  box-shadow: ${p => p.theme.boxShadows.card};
  ${p => p.theme.maxWidth.large`
    height: auto;
    margin: auto;
  `}
  ${p => p.theme.minWidth.tablet`
    cursor: pointer;

    &:hover {
      .gatsby-image-wrapper::after {
        opacity: 0.85;
      }

      .memberDescription {
        opacity: 1;
        visibility: visible;
      }

      .memberName, .memberTitle {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }
  `}
`;

const Image = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  .gatsby-image-wrapper {
    display: block !important;
    width: 100%;
    height: 100%;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-size: 100%;
      transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      background-image: linear-gradient(
        180deg,
        rgba(255, 0, 0, 0.64) -1.51%,
        #f50008 92.67%
      );
      opacity: 0;
    }
  }

  ${p => p.theme.maxWidth.tablet`
    height: auto;
  `}
`;

const Description = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 3em;
  padding: 0 3em;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  ${p => p.theme.maxWidth.tablet`
    font-size: 0.8em;
     background-image: linear-gradient(
        180deg,
        rgba(255, 0, 0, 0.64) -1.51%,
        #f50008 92.67%
      );
  `}
  ${p => p.theme.maxWidth.phone`
    font-size: 1.2em;
    opacity: 1;
    visibility: visible;
  `}
`;

const Name = styled.p`
  font-size: 1.5em;
  font-weight: 800;
  color: ${p => p.theme.colors.white};
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
  transition-delay: 0.25s;
  ${p => p.theme.maxWidth.tablet`
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  `}
`;

const Title = styled.p`
  font-size: 1em;
  font-weight: 400;
  line-height: 2;
  color: ${p => p.theme.colors.white};
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
  transition-delay: 0.33s;
  ${p => p.theme.maxWidth.tablet`
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  `}
`;

type Props = {
  data: {
    name: string;
    title: string;
    image: {
      img: IGatsbyImageData;
      alt: string;
    };
  };
};

export const Member: FC<Props> = ({ data }) => (
  <Wrapper>
    <Container className="card" initial={false}>
      <Image>
        <GatsbyImage
          loading="eager"
          image={data.image.img}
          alt={data.image.alt}
        />
        <Description className="memberDescription">
          <Name className="memberName">{data.name}</Name>
          <Title className="memberTitle">{data.title}</Title>
        </Description>
      </Image>
    </Container>
  </Wrapper>
);
