import React, { FC } from 'react';
import styled from 'styled-components';

import { ButtonEvent } from '~src/utils';

import { ArrowLeftSVG, ArrowRightSVG } from './arrows-svg';

const Wrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  ${p => p.theme.maxWidth.large`
    font-size: 0.8em;
  `}
  ${p => p.theme.maxWidth.wide`
    font-size: 0.6em;
  `}
  
  > svg {
    width: 4em;
    height: 10.875em;
  }
`;

type Props = {
  onClick?: ButtonEvent;
};

export const PrevArrow: FC<Props> = ({ onClick }) => {
  return (
    <Wrapper onClick={onClick} className="prevArrow">
      <ArrowLeftSVG />
    </Wrapper>
  );
};
export const NextArrow: FC<Props> = ({ onClick }) => {
  return (
    <Wrapper onClick={onClick} className="nextArrow">
      <ArrowRightSVG />
    </Wrapper>
  );
};
