import React, { FC } from 'react';
import styled from 'styled-components';

import { LogoSVG } from '~src/components/logo-svg';

import { Members } from './members';

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  color: ${p => p.theme.colors.white};
  padding: 7.5em 1.25em 10em;
  width: 100%;
  ${p => p.theme.maxWidth.tablet`
    padding: 7.5em 5% 5em;
  `}
  ${p => p.theme.maxWidth.phone`
    padding: 7.5em 5%;
  `}
`;

const Content = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding: 0 1.25em;
  ${p => p.theme.minWidth.large`
    max-width: ${p.theme.containerWidth};
  `};
  ${p => p.theme.minWidth.tablet`
    width: 85%;
  `}
  ${p => p.theme.maxWidth.tablet`
    padding: 0;
  `}
   ${p => p.theme.maxWidth.phone`
     margin: auto;
     width: fit-content;
   `}

  svg {
    width: 34.4em;
    height: 4.375em;
    ${p => p.theme.maxWidth.phone`
      width: 19em;
      height: 2.3125em;
      font-size: 1.5em;
    `}
  }
`;

const Title = styled.p`
  font-size: 3.75em;
  font-weight: 800;
  margin-bottom: 1em;
  color: ${p => p.theme.colors.dark};
  ${p => p.theme.maxWidth.phone`
    font-size: 5em;
    margin-bottom: 0.5em;
  `}
`;

export const Team: FC = () => (
  <Wrapper id="team">
    <Container>
      <Content>
        <LogoSVG />
        <Title>task force</Title>
      </Content>
      <Members />
    </Container>
  </Wrapper>
);
