import { motion } from 'framer-motion';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import styled from 'styled-components';

import { Link } from '~src/components/link';

const Wrapper = styled.div`
  padding: 5em 2.5em;
  ${p => p.theme.maxWidth.tablet`
    padding: 2em 10%;
  `}
`;

const Image = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  .gatsby-image-wrapper {
    display: block !important;
    width: 100%;
    height: 100%;
    transition: all 0.25s ease-in-out;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-size: 100%;
      transition: all 0.25s ease-in-out;
      background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.39) -24.08%,
        rgba(0, 0, 0, 0.78) 109.67%
      );
      opacity: 0;
    }
  }

  ${p => p.theme.maxWidth.tablet`
    height: auto;
  `}
`;

const Container = styled(motion.div)`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: auto;
  border-radius: 1.5em;
  transition: all 0.25s ease-in-out;
  box-shadow: ${p => p.theme.boxShadows.card};
  ${p => p.theme.maxWidth.large`
    height: auto;
  `}
  ${p => p.theme.minWidth.tablet`
    cursor: pointer;
    
     &:hover {
      transform: scale(1.08);

      .gatsby-image-wrapper::after {
        opacity: 0.85;
      }
    }
  `}
`;

const Description = styled.div`
  position: absolute;
  top: 2em;
  padding: 0 3em;
  ${p => p.theme.maxWidth.tablet`
    font-size: 0.8em;
  `}
  ${p => p.theme.maxWidth.phone`
    font-size: 1.2em;
  `}
`;

const Before = styled.p`
  font-size: 1.5em;
  color: ${p => p.theme.colors.white};
  line-height: 1;
`;

const Title = styled.p`
  font-size: 1.5em;
  font-weight: 700;
  color: ${p => p.theme.colors.white};
  line-height: 1;
`;

const Work = styled.p`
  font-size: 1.125em;
  color: ${p => p.theme.colors.white};
  line-height: 1;
`;

const ProjectLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8.5em;
  height: 2.5em;
  border: 2px solid #e6e6e6;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 0.875em;
  font-weight: 400;
  letter-spacing: 0.2px;
  color: ${p => p.theme.colors.white};
  position: absolute;
  bottom: 2em;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.25s ease-in-out;
  ${p => p.theme.maxWidth.phone`
    font-size: 1.5em;
  `}

  &:hover {
    background-color: #a0a0a0;
  }
`;

type Props = {
  data: {
    title: string;
    work: string;
    link: string;
    image: {
      img: IGatsbyImageData;
      alt: string;
    };
  };
};

export const Card: FC<Props> = ({ data }) => (
  <Wrapper>
    <Container className="card" initial={false}>
      <Image>
        <GatsbyImage
          loading="eager"
          image={data.image.img}
          alt={data.image.alt}
        />
        <ProjectLink to={data.link}>visit website</ProjectLink>
        <Description>
          <Before>Project</Before>
          <Title>{data.title}</Title>
          <Work>{data.work}</Work>
        </Description>
      </Image>
    </Container>
  </Wrapper>
);
