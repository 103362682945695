import React, { FC } from 'react';
import styled from 'styled-components';

import { LogoHeroSVG } from '~src/components/logo-svg';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${p => p.theme.colors.red};
  ${p => p.theme.maxWidth.phone`
    height: 80vh;
  `}
`;

const Logo = styled(LogoHeroSVG)`
  width: 52em;
  height: 9.75em;
  position: relative;
  z-index: 1;
  ${p => p.theme.maxWidth.phone`
    font-size: 0.5em;
  `}
`;

export const Hero: FC = () => (
  <Wrapper id="hero">
    <Logo />
  </Wrapper>
);
