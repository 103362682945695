import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { graphql, useStaticQuery } from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { FC, useMemo } from 'react';
import Slick from 'react-slick';
import styled from 'styled-components';

import { ImageFile } from '~src/utils';

import { NextArrow, PrevArrow } from './arrows';
import { Slide } from './slide';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  .prevArrow,
  .nextArrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  .prevArrow {
    left: 2%;
  }

  .nextArrow {
    right: 2%;
  }
`;

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  autoplaySpeed: 6000,
  autoplay: true,
  pauseOnHover: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  initialSlide: 0,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 769,
      settings: {
        autoplay: false,
      },
    },
  ],
};

const slideData = [
  {
    id: 1,
    text: `<p>/ The world and life are speeding up,<br/> time is becoming the most valuable asset, nobody wants to waste it. <br/>
Good <strong>Web Development</strong> will help your website deliver client information <br/> 
they need almost instantly and Good <strong>Web Design</strong> <br/>
will create a comfortable environment <br/>
for your clients while they are getting it. <br/> 
<br>8 out of 10 customers visit website before making a decision. <br/>
90% of these customers make a purchase with companies with best user experience. <br/>
Speed, comfort, user friendly website are more appriciated <br/>
by clients and your very important partner, Google. <br/>
We can provide these three features through simple website solution <br/>
like Word press or more complex like React. /</p>`,
  },
  {
    id: 2,
    text: `<p>/ Business 2.0 is simply stating that modern business can not do without <br/>
good <strong>Digital Marketing</strong>. Customers are turning to new sales channels. <br/>
Facebook, Instagram, YouTube, Google are tools that need to be utilized now more than ever. <br/>
<br>There are 3.5 billion users of social media worldwide. 7 out of 10 clients <br/>
with positive website experience will recommend you to their friends. <br/>
How to utilize these tools in proper way?  Let us prepare for you the content <br/>
that will attract the right audience for you, customers. <br/>
Research that combined new technologies, trends and newly occurred events <br/>
worldwide have shown that in next years successful companies will increase <br/>
percentage of digital  marketing in overall marketing budget up to 50%. <br/>
<strong>The game is definitely changing, stay in it.</strong> /</p>`,
  },
  {
    id: 3,
    text: `<p>/ Improve your site quality, get noticed. <br/>
Good text,  well-defined text, accurate text, right content, quality information… <br/>
Content that will answer customers questions, inquiries or request. <br/>
<br>Who are these <strong>Search Engine Optimization</strong> experts? <br/>
Imagine a chef who is cooking 5 meals at the same time for you. <br/>
For your business each as perfectly as important as the other. <br/>
He is the a master chef and his greatest dishes are Content, Link building, <br/>
Technical SEO, Social media, Keyword research. <br/>
The best thing about our master chef is all his dishes are “organic,” and “natural”. <br/>
The exact one we need. The one Google says YES to. <br/>
Our conversion rate optimization solutions<br/> are focused on transforming website visitors to satisfied customers. /</p>`,
  },
  {
    id: 4,
    text: `<p>/ Offer your services or products to Local and Global market. <br/>
Do not limit yourself geographically. Trade globally! <br/>
No working hours for <strong>E-commerce</strong>, constant availability. <br/>
Do not limit yourself to standard working hours <br/>
or specific time zone. Offer your products and services to a customer <br/>
at a time most comfortable for them. <br/>
Additional and very powerful sales channel with no additional employee costs. <br/>
Upon set-up E-commerce usually do not require special maintenance. <br/>
E commerce will provide valuable information about your customers. <br/>
Get to now all you need to know about them. /</p>`,
  },
  {
    id: 5,
    text: `<p>/ Your digital identity must stand for <br/>
„This is who I am, and this is what I do“. <br/>
Our <strong>designers</strong> and marketing experts, in cooperation with you <br/>
will create a digital identity you are comfortable with. <br/>
It is very important to attract the consumers attention <br/>
and make them think positively about the products and the brand. <br/>
Having a positive image can be highly beneficial <br/>
in these times of intense competition. /</p>`,
  },
  {
    id: 6,
    text: `<p>/ <strong>Video content</strong> must be crisp, fresh, <br/>
it needs to communicate with the audience. <br/>
Proper video content prepared by video edit experts <br/>
will be more favored by YouTube, <br/>
which will help your overall digital identity <br/>
and help stand you out from the crowd. <br/>
Good video content is 5x more likely <br/>
to transform visitor to a customer than content alone. /</p>`,
  },
];

const slideQuery = graphql`
  query SlideQuery {
    allFile(filter: { relativeDirectory: { eq: "slides" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 900
              height: 950
              formats: [AUTO, WEBP]
              placeholder: NONE
            )
          }
        }
      }
    }
  }
`;

type SlideQuery = {
  allFile: {
    edges: {
      node: ImageFile;
    }[];
  };
};

export const Services: FC = () => {
  const {
    allFile: { edges },
  } = useStaticQuery<SlideQuery>(slideQuery);

  const slides = useMemo(() => {
    const nodes = edges.sort((a, b) => {
      const nameA = a.node.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.node.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });

    return slideData.map((slide, i) => ({
      ...slide,
      image: {
        img: getImage(nodes[i].node) as IGatsbyImageData,
        alt: nodes[i].node.name,
      },
    }));
  }, [edges]);

  return (
    <Wrapper id="services">
      <Slick {...settings}>
        {slides.map(slide => (
          <Slide key={slide.id} data={slide} />
        ))}
      </Slick>
    </Wrapper>
  );
};
