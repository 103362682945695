import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import styled from 'styled-components';

import { Phone as PhoneIcon } from '~src/components/icons';
import { Link } from '~src/components/link';
import { ImageFile } from '~src/utils';

const Wrapper = styled.div`
  width: 100%;
  height: 53em;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fc;
  ${p => p.theme.maxWidth.xLarge`
    font-size: 0.9em;
  `}
  ${p => p.theme.maxWidth.tabletLandscape`
    font-size: 0.8em;
  `}
  ${p => p.theme.maxWidth.tablet`
    font-size: 0.7em;
  `}
  ${p => p.theme.maxWidth.phone`
    height: auto;
    padding: 5em 0;
    font-size: 1em;
  `}
`;

const Image = styled(GatsbyImage)<{ image: IGatsbyImageData }>`
  position: absolute !important;
  top: -10em;
  left: 0;
  width: 32em;
  z-index: 1;
  ${p => p.theme.maxWidth.phone`
    display: none !important;
  `}
`;

const ImagePhone = styled(GatsbyImage)<{ image: IGatsbyImageData }>`
  width: 20.625em;
  height: 28.75em;
  margin: auto;
  font-size: 1.3em;
  ${p => p.theme.minWidth.phone`
    display: none !important;
  `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const Text = styled.p`
  font-size: 3.75em;
  font-weight: 800;
  color: ${p => p.theme.colors.dark};
`;

const Mail = styled(Link)`
  font-size: 3.75em;
  font-weight: 800;
  color: ${p => p.theme.colors.red};
  margin-bottom: 0.4em;
  transition: opacity 0.2s ease-in-out;
  ${p => p.theme.maxWidth.tablet`
    font-size: 3em;
  `}
  ${p => p.theme.maxWidth.phone`
    font-size: 2em;
  `}

  &:hover {
    opacity: 0.7;
  }
`;

const Phone = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5em;
  font-weight: 700;
  color: ${p => p.theme.colors.dark};
  transition: opacity 0.2s ease-in-out;
  ${p => p.theme.maxWidth.phone`
    font-size: 2em;
  `}

  &:hover {
    opacity: 0.7;
  }

  > svg {
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.4em;
  }
`;

const mailBoxQuery = graphql`
  query MailBoxQuery {
    mailBox: file(relativePath: { eq: "sanduce.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 550
          height: 1300
          formats: [AUTO, WEBP]
          placeholder: NONE
        )
      }
    }
    mailBoxPhone: file(relativePath: { eq: "sanduce-phone.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 330
          height: 450
          formats: [AUTO, WEBP]
          placeholder: NONE
        )
      }
    }
  }
`;

type MailBoxQuery = {
  mailBox: ImageFile;
  mailBoxPhone: ImageFile;
};

export const Contact: FC = () => {
  const image = useStaticQuery<MailBoxQuery>(mailBoxQuery);

  const desktop = getImage(image.mailBox) as IGatsbyImageData;
  const phone = getImage(image.mailBoxPhone) as IGatsbyImageData;

  return (
    <Wrapper id="contact">
      <Image image={desktop} alt="" />
      <Content>
        <Text>Contact Us</Text>
        <ImagePhone image={phone} alt="" />
        <Mail to={`mailto: info@philipandmax.com`}>info@philipandmax.com</Mail>
        <Phone to={`tel: +381 64 109 85 17`}>
          <PhoneIcon /> +381 64 109 85 17
        </Phone>
      </Content>
    </Wrapper>
  );
};
