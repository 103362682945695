import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { graphql, useStaticQuery } from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { FC, useMemo } from 'react';
import Slick from 'react-slick';
import styled from 'styled-components';

import { ImageFile } from '~src/utils';

import { Member } from './member';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  autoplay: true,
  pauseOnHover: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  swipeToSlide: true,
  initialSlide: 1,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        speed: 300,
      },
    },
    {
      breakpoint: 769,
      settings: {
        autoplay: false,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        autoplay: false,
        dots: true,
      },
    },
  ],
};

const membersData = [
  {
    id: 1,
    name: `Mina`,
    title: `/Google/Social Media/`,
  },
  {
    id: 2,
    name: `Stefan`,
    title: `/Web Design/Google Marketing/`,
  },
  {
    id: 3,
    name: `Strahinja`,
    title: `/Google/Social Media/`,
  },
  {
    id: 4,
    name: `Maja`,
    title: `/Copywrite/SEO/`,
  },
  {
    id: 5,
    name: `Dušan`,
    title: `/Development/`,
  },
  {
    id: 6,
    name: `Marko`,
    title: `/Development/`,
  },
  {
    id: 7,
    name: `Danilo`,
    title: `/Development/`,
  },
  {
    id: 8,
    name: `Alex`,
    title: `/Graphic Design/`,
  },
  {
    id: 9,
    name: `Nemanja`,
    title: `/Animation/Video Editing/`,
  },
];

const membersQuery = graphql`
  query MembersQuery {
    allFile(filter: { relativeDirectory: { eq: "team" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 365
              height: 365
              formats: [AUTO, WEBP]
              placeholder: NONE
            )
          }
        }
      }
    }
  }
`;

type MembersQuery = {
  allFile: {
    edges: {
      node: ImageFile;
    }[];
  };
};

export const Members: FC = () => {
  const {
    allFile: { edges },
  } = useStaticQuery<MembersQuery>(membersQuery);

  const members = useMemo(() => {
    const nodes = edges.sort((a, b) => {
      const nameA = a.node.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.node.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });

    return membersData.map((member, i) => ({
      ...member,
      image: {
        img: getImage(nodes[i].node) as IGatsbyImageData,
        alt: nodes[i].node.name,
      },
    }));
  }, [edges]);

  return (
    <Wrapper>
      <Slick {...settings}>
        {members.map(member => (
          <Member key={member.id} data={member} />
        ))}
      </Slick>
    </Wrapper>
  );
};
