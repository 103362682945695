import React, { FC } from 'react';
import styled from 'styled-components';

import { Cards } from './cards';

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e6e6e6;
`;

const Container = styled.div`
  color: ${p => p.theme.colors.white};
  padding-top: 5em;
  padding-bottom: 10em;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  ${p => p.theme.minWidth.large`
    max-width: ${p.theme.containerWidth};
  `};
  ${p => p.theme.minWidth.tablet`
    width: 85%;
  `}
  ${p => p.theme.maxWidth.tablet`
    padding-bottom: 5em;
  `}
`;

const Content = styled.div`
  padding: 0 1.25em;
  color: ${p => p.theme.colors.dark};
  ${p => p.theme.maxWidth.tablet`
    padding: 0 10%;
  `}
`;

const Title = styled.h2`
  font-size: 3.75em;
  font-weight: 800;
  margin-bottom: 0.2em;
`;

export const Projects: FC = () => (
  <Wrapper id="projects">
    <Container>
      <Content>
        <Title>Our Portfolio</Title>
      </Content>
      <Cards />
    </Container>
  </Wrapper>
);
