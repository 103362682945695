import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import {
  About,
  Contact,
  Hero,
  Projects,
  Services,
  Team,
} from '~src/components/pages/home';
import Layout from '~src/layouts/page-layout';

const seoData = {
  title: ``,
  description: ``,
  featuredImage: ``,
  featuredImageAlt: ``,
};

const IndexPage: FC<PageProps> = ({ location }) => {
  return (
    <Layout seoData={seoData} pathname={location.pathname}>
      <Hero />
      <Services />
      <Projects />
      <About />
      <Team />
      <Contact />
    </Layout>
  );
};

export default IndexPage;
