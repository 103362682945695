import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { graphql, useStaticQuery } from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { FC, useMemo } from 'react';
import Slick from 'react-slick';
import styled from 'styled-components';

import { ImageFile } from '~src/utils';

import { Card } from './card';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  autoplay: true,
  pauseOnHover: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  swipeToSlide: true,
  initialSlide: 1,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        speed: 300,
      },
    },
    {
      breakpoint: 769,
      settings: {
        autoplay: false,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        autoplay: false,
        dots: true,
      },
    },
  ],
};

const cardsData = [
  {
    id: 1,
    title: `Durmitor Adventure`,
    work: `Development`,
    link: `https://durmitoradventure.com`,
  },
  {
    id: 2,
    title: `Beer Shop`,
    work: `Development`,
    link: `https://pivnica.netlify.app`,
  },
  {
    id: 3,
    title: `Majer Promo-Product`,
    work: `Design/Development`,
    link: `https://www.majer.rs`,
  },
  {
    id: 4,
    title: `Online Casino/Game`,
    work: `UI/UX`,
    link: `https://soccerbet.rs/#gambling`,
  },
  {
    id: 5,
    title: `Barbieri Machines`,
    work: `Design/Development`,
    link: `https://www.barbierisrl.com`,
  },
  {
    id: 6,
    title: `Barum Home`,
    work: `Design/Development`,
    link: `https://www.barumhome.rs`,
  },
  {
    id: 7,
    title: `Weiss Light Official`,
    work: `Design/Development`,
    link: `https://www.weisslight.eu`,
  },
  {
    id: 8,
    title: `Weiss Light Webshop`,
    work: `Design/Development`,
    link: `https://www.weiss.rs`,
  },
  {
    id: 9,
    title: `Barbieri Online Software`,
    work: `Design/Development`,
    link: `https://www.sparepartsshops.com`,
  },
];

const cardsQuery = graphql`
  query CardsQuery {
    allFile(filter: { relativeDirectory: { eq: "portfolio" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 390
              height: 600
              formats: [AUTO, WEBP]
              placeholder: NONE
            )
          }
        }
      }
    }
  }
`;

type CardsQuery = {
  allFile: {
    edges: {
      node: ImageFile;
    }[];
  };
};

export const Cards: FC = () => {
  const {
    allFile: { edges },
  } = useStaticQuery<CardsQuery>(cardsQuery);

  const cards = useMemo(() => {
    const nodes = edges.sort((a, b) => {
      const nameA = a.node.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.node.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });

    return cardsData.map((card, i) => ({
      ...card,
      image: {
        img: getImage(nodes[i].node) as IGatsbyImageData,
        alt: nodes[i].node.name,
      },
    }));
  }, [edges]);

  return (
    <Wrapper>
      <Slick {...settings}>
        {cards.map(card => (
          <Card key={card.id} data={card} />
        ))}
      </Slick>
    </Wrapper>
  );
};
