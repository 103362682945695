import React, { FC, memo, SVGProps } from 'react';

type Props = {
  className?: string;
};

export const ArrowRightSVG: FC<SVGProps<SVGSVGElement> & Props> = memo(
  ({ className = '' }) => (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 65 175"
      fill="#E0E0E0"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.083 3.255c3.6-2.058 8.23-.833 10.305 2.738L61.653 83.75a7.303 7.303 0 01.994 3.997 7.454 7.454 0 01-.994 3.997l-45.265 77.756c-2.075 3.571-6.722 4.796-10.305 2.738-3.6-2.058-4.835-6.65-2.76-10.221l43.241-74.287L3.322 13.442c-2.074-3.571-.84-8.163 2.761-10.22v.033z"
      />
    </svg>
  )
);

export const ArrowLeftSVG: FC<SVGProps<SVGSVGElement> & Props> = memo(
  ({ className = '' }) => (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 65 175"
      fill="#E0E0E0"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.917 171.73c-3.6 2.058-8.23.833-10.305-2.738L3.347 91.236a7.303 7.303 0 01-.994-3.997 7.454 7.454 0 01.994-3.996L48.612 5.486C50.687 1.915 55.333.69 58.917 2.748c3.6 2.058 4.835 6.65 2.76 10.221L18.437 87.257l43.242 74.287c2.074 3.571.84 8.163-2.761 10.221v-.034z"
        fill="#E0E0E0"
      />
    </svg>
  )
);
