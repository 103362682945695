import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  background-color: ${p => p.theme.colors.red};
  ${p => p.theme.maxWidth.phone`
    flex-direction: column;
    padding-left: 10%;
    padding-right: 10%;
  `}
`;

const LeftBox = styled.div`
  max-width: 53.6em;
  width: 100%;
  height: 45.125em;
  padding-top: 6em;
  padding-left: 14em;
  position: relative;
  ${p => p.theme.maxWidth.large`
    padding-left: 10em;
  `}
  ${p => p.theme.maxWidth.tablet`
    font-size: 0.7em;
  `}
  ${p => p.theme.maxWidth.phone`
    padding-left: 0;
    padding-top: 8em;
    height: auto;
    margin-bottom: 5em;
  `}

  &::before {
    content: '';
    position: absolute;
    top: -6em;
    bottom: 6em;
    left: 8em;
    right: -8em;
    border: 2.875em solid #000;
    mix-blend-mode: soft-light;
    will-change: opacity;
    ${p => p.theme.maxWidth.large`
      left: 3em;
      right: -3em;
    `}
    ${p => p.theme.maxWidth.phone`
      display: none;
    `}
  }
`;

const LeftTitle = styled.p`
  font-size: 9em;
  font-weight: 800;
  color: ${p => p.theme.colors.white};
  line-height: 0.8;
`;

const LeftListItem = styled.p`
  font-size: 3.75em;
  font-weight: 900;
  letter-spacing: -0.055em;
  line-height: 1;
  color: #b4b4b4;
`;

const RightBox = styled.div`
  margin-left: 20em;
  margin-top: auto;
  margin-bottom: auto;
  max-width: 26em;
  width: 100%;
  padding: 5% 0% 5% 0;
  ${p => p.theme.maxWidth.xLarge`
    margin-left: 15em;
  `}
  ${p => p.theme.maxWidth.large`
    margin-left: 8em;
    padding: 5% 5% 5% 0;
  `}
  ${p => p.theme.maxWidth.tablet`
    margin-left: 6em;
  `}
   ${p => p.theme.maxWidth.phone`
    margin: 0;
    margin-bottom: 5em;
    font-size: 1.2em;
  `}
`;

const RightBoxTop = styled.div`
  padding-bottom: 3em;
`;

const RightBoxBottom = styled.div``;

const RightTitle = styled.p`
  font-size: 1.875em;
  font-weight: 400;
  line-height: 1.2;
  color: ${p => p.theme.colors.white};
  padding-bottom: 1em;
`;

const RightText = styled.p`
  font-size: 1.125em;
  font-weight: 400;
  line-height: 1.3;
  color: ${p => p.theme.colors.white};
  opacity: 0.75;
`;

export const About: FC = () => (
  <Wrapper id="about">
    <LeftBox>
      <LeftTitle>We are</LeftTitle>
      <LeftListItem>Designers</LeftListItem>
      <LeftListItem>Copywriters</LeftListItem>
      <LeftListItem>Marketing Specialists</LeftListItem>
      <LeftListItem>Developers</LeftListItem>
    </LeftBox>
    <RightBox>
      <RightBoxTop>
        <RightTitle>We belive</RightTitle>
        <RightText>
          We at Philip&Max firmly believe that together with you with the right
          approach and precise analysis of your activities, we can move
          boundaries in branding, promotion and development of your company.
          Ongoing successful projects are testimonials to our efforts and and a
          proof that we have found new ways in attracting right audience.
        </RightText>
      </RightBoxTop>
      <RightBoxBottom>
        <RightTitle>1 project - Whole team</RightTitle>
        <RightText>
          When you are cooperating with Philip&Max you will have 9 professionals
          constantly at your disposal. We value experience of each of our
          members and we belive that this exact experience can bring added value
          to your brand and your company.
        </RightText>
      </RightBoxBottom>
    </RightBox>
  </Wrapper>
);
