import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 4em;
  ${p => p.theme.maxWidth.tablet`
    flex-direction: column;
    align-items: center;
  `}
`;

const ImgBox = styled.div`
  position: relative;
  width: 56.25em;
  height: 60em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9em;
  ${p => p.theme.maxWidth.large`
    font-size: 0.8em;
  `}
  ${p => p.theme.maxWidth.wide`
    font-size: 0.6em;
  `}
`;

const Image = styled(GatsbyImage)<{ image: IGatsbyImageData }>`
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: -2;

  & > img {
    object-fit: cover !important;
    object-position: 50% 50% !important;
  }
`;

const Text = styled.div`
  margin: auto;

  > p {
    font-size: 1.125em;
    font-weight: 400;
    line-height: 1.4;
    color: ${p => p.theme.colors.dark};
    margin-bottom: 0;
  }

  ${p => p.theme.maxWidth.tablet`
    margin-bottom: 4em;
  `}
  ${p => p.theme.maxWidth.phone`
    max-width: 80%;
    margin: auto;
    margin-bottom: 4em;
  `}
`;

type Props = {
  data: {
    text: string;
    image: {
      img: IGatsbyImageData;
      alt: string;
    };
  };
};

export const Slide: FC<Props> = ({ data }) => (
  <Wrapper>
    <ImgBox>
      <Image loading="eager" image={data.image.img} alt={data.image.alt} />
    </ImgBox>
    <Text dangerouslySetInnerHTML={{ __html: data.text }}></Text>
  </Wrapper>
);
